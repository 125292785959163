import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { UserSessionService } from '../services/usersession.service';
import { PowerBiService } from '../services/power-bi.service';
import { catchError, map } from 'rxjs/operators';
import { of } from 'rxjs';

@Injectable()

export class PowerBIAuthGuard implements CanActivate {

  constructor(private userSessionService: UserSessionService, 
              private powerBIService:PowerBiService,
              private router: Router) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> {
      const url: string = state.url;
      return this.powerBIService.hasPermission().pipe(
        map((hasAccess) => {
          if (hasAccess && url.startsWith("/powerbireport")) {
            return true; // Allow access to Power BI reports if the user has permissions
          } else if (url.startsWith("/report") && !hasAccess) {
            return true; 
          } else {
            this.router.navigateByUrl('');
            return false;
          }
        }),
        catchError(() => {
          this.router.navigateByUrl('');
          return of(false); // Handle error by denying access and optionally redirecting
        })
      );
    }
}