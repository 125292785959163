import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { AppInsightsService } from './app-insights.service';
import { environment } from 'src/environments/environment';
import { Observable, of, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { BehaviorSubject } from 'rxjs';
import {AppService} from './app.service';
import { DatePipe } from '@angular/common';

@Injectable({
  providedIn: 'any'
})
export class PowerBiService {
  pipe;
  baseURL = this.appService.config.apiBaseUrl;
  constructor(private http: HttpClient,
              private appinsights: AppInsightsService,
              private appService: AppService) {}


  GetReportDetails(ReportRequestModel: any): Observable<any> {
    this.pipe = new DatePipe('en-US');
    let pdate = this.pipe.transform(new Date(), 'yyyy-MM-dd');
    let ptime = this.pipe.transform(new Date(), 'HH:mm:ss');
    ReportRequestModel["PrintedDate"] = pdate + 'T' + ptime;
    const options = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
    let URL= `${this.baseURL}/PowerBI/GenerateReport`;
    return this.http.post<any>(URL, ReportRequestModel, options)
        .pipe(
            map(res => {
                console.log(res);
                return res;
            }),
            catchError(this.handleError)
        );
}

public downloadFile(ReportRequestModel: any){
  this.pipe = new DatePipe('en-US');
  let pdate = this.pipe.transform(new Date(), 'yyyy-MM-dd');
  let ptime = this.pipe.transform(new Date(), 'HH:mm:ss');
  ReportRequestModel["PrintedDate"] = pdate + 'T' + ptime;
  const options = { headers: new HttpHeaders({ 'responseType': 'blob' }) };
    let URL= `${this.baseURL}/PowerBI/ExportReport`; 
    return this.http.post(URL, ReportRequestModel, { responseType: 'blob'})
    .pipe(
        map(res => {
        return res;
    }),
    catchError(this.handleError)
    );
}

public downloadCSVFile(ReportRequestModel: any){
  this.pipe = new DatePipe('en-US');
  let pdate = this.pipe.transform(new Date(), 'yyyy-MM-dd');
  let ptime = this.pipe.transform(new Date(), 'HH:mm:ss');
  ReportRequestModel["PrintedDate"] = pdate + 'T' + ptime;
  const options = { headers: new HttpHeaders({ 'responseType': 'blob' }) };
    let URL= `${this.baseURL}/PowerBI/ExportDatasetAsCSV`; 
    return this.http.post(URL, ReportRequestModel, { responseType: 'blob'})
    .pipe(
        map(res => {
        return res;
    }),
    catchError(this.handleError)
    );
}

public downloadTxtFile(ReportRequestModel: any){
  this.pipe = new DatePipe('en-US');
  let pdate = this.pipe.transform(new Date(), 'yyyy-MM-dd');
  let ptime = this.pipe.transform(new Date(), 'HH:mm:ss');
  ReportRequestModel["PrintedDate"] = pdate + 'T' + ptime;
  const options = { headers: new HttpHeaders({ 'responseType': 'blob' }) };
    let URL= `${this.baseURL}/PowerBI/ExportDatasetAsTxt`; 
    return this.http.post(URL, ReportRequestModel, { responseType: 'blob'})
    .pipe(
        map(res => {
        return res;
    }),
    catchError(this.handleError)
    );
}

GetReports(): Observable<any> {
  return this.http.get<any[]>(
    `${this.baseURL}/PowerBI/GetWorkSpaceReports`,
    { observe: 'response' })
    .pipe(
        map(res => {
            const reports = res.body as any[];
            return reports;
        }),
        catchError(this.handleError)
    );
}


hasPermission(): Observable<boolean> {
  return this.http.get<any[]>(
    `${this.baseURL}/PowerBI/GetPowerBIAccess`,
    { observe: 'response' })
    .pipe(
        map(res => {
          return res.body as any;
        }),
        catchError(this.handleError)
    );
}

private handleError(error: HttpErrorResponse) {
console.error('server error:', error);
if (error.error instanceof Error) {
    const errMessage = error.error.message;
    this.appinsights.logEvent(errMessage);
    return throwError(errMessage);
}
return throwError(error || 'Node.js server error');
}
}
